.DateTimePicker {

    label {
        font-weight: normal;
        font-style: italic;
    }

    .DateTimePicker__date {
        display: flex;
        align-items: center;

        select {
            width: auto;
        }
    }
    .DateTimePicker__time {
        display: flex;
        align-items: center;

        select {
            margin-bottom: 0;
        }
    }
}