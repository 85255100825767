@font-face {
    font-family: 'avenir';
    src: font-url('/fonts/avenir_book.woff2') format('woff2'),
         font-url('/fonts/avenir_book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src: font-url('/fonts/avenir_heavy.woff2') format('woff2'),
         font-url('/fonts/avenir_heavy.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@mixin avenir() {
    font-family : 'avenir', 'Helvetica Neue', 'Helvetica', '_sans';
}

body {
    @include avenir;
}

h1,
h2,
h3,
h4 {
    @include avenir;
    font-weight: bold;
    line-height: 1.1;
}

@mixin link() {
    color: $brown1;
    &:hover { color: $brown2; }
    text-decoration: underline;
}

a {
    @include link;
}

.link-button {
    @include link;
    font-family: inherit;
}