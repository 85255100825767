@import '../scss/_variables.scss';

.AskAccidentPrompt {

 	@include modaloverlay;

	.AskAccidentPrompt__modal {
		@include modal;
		text-align: center;

		button:not(.link-button) {
			color: #FFF;
			padding: 1em 2em;
			display: block;
			width: 100%;
			font-weight: bold;
			text-transform: uppercase;
		}
		.success-btn {
			background-color: $successgreen;

		}
		.accident-btn {
			background-color: $accidentred;
			margin-top: 1em;
		}
		.cancel {
			margin-top: 1em;
			display: inline-block;
		}
	}

	&.AskAccidentPromt--sending {
		button {
			opacity: 75%;
		}
	}
}