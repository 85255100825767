
.button {
    display:inline-block;
    font-weight: bold;
}

// foundation override
.button { background-color: $blue1; }
.button:hover,
.button:focus { background-color: $blue2; }

// foundation override
.button.primary { background-color: $orange; }
.button.primary:hover,
.button.primary:focus { background-color: $orange; }

// foundation override
.button.secondary { background-color: $brown1; }
.button.secondary:hover,
.button.secondary:focus { background-color: $browner1; }

.activity-buttons {

    // margin-bottom: 2em;

    .button-group.expanded .button,
    .button {
        width: initial!important;

        //@TODO foundation override
        &:last-child { margin-right: 0!important; } 
    }

    .button-group {
        margin-bottom: 0;
    }

    &.food { margin-bottom: 2em; }


    .button.expanded {
        flex-grow: 4;
    }

    &.pee,
    &.poo {

        .accident {

            .button {
                // margin-right:1px;
                border-right: 1px solid $white;
            }
        }

        > .expanded > .button.primary { text-indent: 10%; }

    }

    .dropdown-panel {
        max-height: 0;
        overflow:hidden;
        width: 100%;
        position: static;
        // margin-bottom: 1rem;
        transition: max-height .25s linear;
        .button-group {
            margin-bottom: 0;
            margin-right: 51px; //@todo
        }
        .button.small-text {
            // padding: .5em;
            font-size: .8rem;
        }
        .button[is-accident="false"] {
            padding:.5em;
        }
    }
    .dropdown-panel.open {
        max-height: 260px;
    }

    input[type="checkbox"]:checked + .dropdown-panel {
        max-height: 585px; //@TODO
    }
    

}

.pee .button{
    background-color: $yellow1;
    &:hover,
    &:focus,
    &.dropdown {
        background-color: $yellow2;
    };
}
.poo .button{
    background-color: $brown1;
    &:hover,
    &:focus,
    &.dropdown {
        background-color: $brown2;
    };
}
.food .button{
    background-color: $red1;
    &:hover,
    &:focus,
    &.dropdown {
        background-color: $red2;
    };
}
.water .button{
    background-color: $blue1;
    &:hover,
    &:focus,
    &.dropdown {
        background-color: $blue2;
    };
}

// show buttons
.button-group.expanded {
    display:flex;
}
