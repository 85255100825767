@import '../scss/_variables.scss';

.AsyncButton {
    display:inline-block;
    font-weight: bold;
    text-align: center;
    background-color: $brown1;
    color: white;
    padding: .5em 1em;
    cursor: pointer;
}
