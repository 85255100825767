

.history {
    margin-bottom: 1em;
}

.history__row {
    display:flex;
    justify-content: space-between;
    line-height: 1;
    margin-bottom: .5rem;
    align-items: baseline;

    .note-icon {
        margin-left: .5em;
    }
}

.history__icon {
    margin-right: 2em;
}

.history__time {
    margin-right: .5em;
}

.history__dots {
    flex-grow: 4;
    border-bottom: 2px dotted $black;
    height: 1.2em;
}

// .history__delete-btn {
//     &.button {
//         background-color:transparent;
//         color: red;

//         &:hover {
//             background-color: red;
//             color: white;
//         }
//     }

// }

.history-time-mode-control {
    button {
        background-color: $brown1;
    }
    button:hover,
    button:focus {
        background-color: $brown1;
    }
    button.hollow {
        border-color: $brown1;
        color: $brown1;
    }
    button.hollow:hover,
    button.hollow:focus {
        border-color: $brown1;
        color: $brown1;
    }
}