@import 'variables';

.header {
    position: fixed;
    top: 0;
    // left: 0;
    right: 0;
    z-index: $header-z;

    background-color: $orange;

    .hamburger:focus {
        outline: none;
    }

    .hamburger label {
        line-height: .9;
    }
}

#navigation-on {
    display:none;
}
.navigation {
    // display:none;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    max-height: 0;
    transition: max-height .5s;
    overflow:hidden;
    background-color: $orange;
    // box-sizing: border-box;
    // padding-top: 2em;
    z-index: -1;

    &,a {
        color: #FFF;
    }

    .menu {
        padding-top: 3em;
        padding-bottom: 1em;
    }


}
.hamburger.is-active + .navigation {
    // display: block;
    max-height: 382px;
}

/*
  .hamburger--elastic {
    .hamburger-inner {
      top: $hamburger-layer-height / 2;
      transition-duration: 0.4s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition: opacity 0.15s 0.4s ease;
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }

    &.is-active {
      .hamburger-inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

        transform: translate3d(0, $y-offset, 0) rotate(135deg);
        transition-delay: 0.1s;

        &::before {
          transition-delay: 0s;
          opacity: 0;
        }

        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(-270deg);
          transition-delay: 0.1s;
        }
      }
    }

*/
