.edit_puppy {

    h1 {
        margin-bottom: 1rem;
        input {
            font-size: inherit;
            font-weight: bold;
        }
    }

    .puppyshot {
        margin-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;
        display:block;
    }
}