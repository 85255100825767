/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
 * or any plugin's vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any styles
 * defined in the other CSS/SCSS files in this directory. It is generally better to create a new
 * file per style scope.
 *
 *= require foundation

 */
@import "variables";
@import "button";
@import "typography";
@import "nexts";
@import "history";
@import "hamburgers";
@import "header";
@import "puppies";
@import "notifications";
@import "edit-puppy";
@import "forms";
@import "homepage";
@import "footer";

html {
    font-size: 20px;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

ul {
    padding:0;
    margin-right:0;
    margin-left:0;

    li { list-style:none; }
}

.container {
    padding-left:1em;
    padding-right:1em;
    padding-top: 3rem;
    padding-bottom: 1rem;
    flex-grow: 1;
}

$low-risk-color: green;
$medium-risk-color: orange;
$high-risk-color: red;

.progress-meter {
    max-width:100%;
    min-width:0.5%;
    &[data-risk="low"] { background-color: $low-risk-color; }
    &[data-risk="medium"] { background-color: $medium-risk-color; }
    &[data-risk="high"] { background-color: $high-risk-color; }
}

.progress {
    border: 1px solid #333;
    background-color: transparent;
    height:2.5em;
}

.pee-icon {
    filter: hue-rotate(225deg)
}
// .progress.right {
//     position:relative;
//     .progress-meter {
//         position:absolute;
//         right:0;
//     }
// }

.close-button {
    .button {
        margin:0;
    }
}

.u-pushup-1 {
    margin-top: 1em;
}

.u-pushup-2 {
    margin-top: 2em;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}