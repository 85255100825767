.home {

	max-width: 42em;
	margin-left: auto;
	margin-right: auto;

	h1 {
		text-align: center;
		margin-top: 1rem;
	} 

	.subtitle {
		display: block;
		font-size: 1.125rem;
		text-align: center;
		// color: $blue1;
	}

	.stops {
		margin-top: 2rem;
		// text-align: center;
		font-size: 170%;

		ul {
			max-width: 16em;
			margin-left: auto;
			margin-right: auto;
		}

		li {
			line-height: 1;
			span { 
				font-weight: bold; 
				color: $orange;
			}
		}

		li + li { margin-top: .5em; }
	}

	.overview {

		ul {
			list-style: none;
			max-width: 42em;
			margin-left: auto;
			margin-right: auto;
		}

		li {
			text-align: left;
			text-align: center;
			margin-top: 1.5em;

		}
		li + li {
		}

		.icon {
			// display: block;
		}

		.bigword {
			display: block;
			font-size: 1.5em;
			line-height: 1;
			text-transform: uppercase;
		}

		.parenthetical {
			display: block;
			font-style: italic;
			font-size: 75%;
			color: $brown1;
		}

	}

	.two-buttons {
		margin-top: 2em;
		.button {}
		// .button + .button { margin-top: 1em; }
	}

	.start-button,
	.learn-button {
		display: block;
		max-width: 18em;
		margin-left: auto;
		margin-right: auto;

	}
	.start-button {
		background-color: $purple1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.learn-button {
		background-color: $orange;

		span:first-child {
			text-transform: uppercase;
		}
		span + span {
			display: block;
			font-size: 70%;
			text-decoration: none;
		}
	}


	.crash-course {
		margin-top: 2rem;

		form input[type="submit"] { background-color: $orange; }
	}

	.features {
		
		ul {
			text-align: left;
		}
		li {
			span {
				display: inline-block;
				width: 1.5em;
				margin-left: -1.5em;
				text-align: center;
			}
			padding-left: 1.5em;
			// text-indent: -2em;

		}
		li + li {
			margin-top: 1em;
		}
	}

	h2 {
		margin-top: 2em;
		color: $brown2;
	}

	.faqs {
		// text-align: left;

		dt {
			line-height: 1.25;
			margin-top: 1em;

			span {
				display: inline-block;
				width: 1.5em;
				margin-left: -1.5em;
				text-align: center;
			}
			padding-left: 1.5em;

		}
		dd {
			line-height: 1.5;
			padding-left: 1.5em;
		}

	}

	.screenshots {
		margin-top: 2em;
	}
	.screenshot {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 30px;
		background-color: #000;
		border-radius: 20px;

		img {
			display: block;
		}

		.bottom {
			background-color: #000;
			padding: 20px;
			.buttonn {
				background-color: #888;
				border-radius: 50%;
				height: 40px;
				width: 40px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		& + .screenshot { margin-top: 1em; }

	}

	@media all and (min-width: 30em) {
		.subtitle {
			font-size: 1.5rem;
		}

		.screenshots {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 2em;
		}
		.screenshot + .screenshot { margin-top: 0; }
	}

	@media all and (min-width: 40em) {
		// .subtitle {
		// 	font-size: 1.75rem;
		// }

		.overview ul {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 1em;
			margin-top: 1.75rem;
		}

		.overview .bigword {
			font-size: 1.75em;
		}

		.start-button,
		.learn-button {
			font-size: 1.25rem;
			// max-width: 14em;
		}

		.two-buttons {
			margin-top: 3rem;
			display: flex;
			justify-content: center;
			.button + .button { margin-left: 1em; }
			.button {
				flex-basis: 50%;
			}
		}
	}

}
