$yellow1 : #F1C540;
$yellow2 : #c7a43b;

// $brown1 : #6E5D35;
// $brown1 : #80672B;
$brown1 : #997B33;
$browner1 : #6F5B2C;
$brown2 : #2D250C;

$blue1 : #5A8CDA;
$blue2 : #2D476F;

$red1 : #F24722;
$red2 : #812614;

$black : #2D250C;

$orange : #F79329;

$purple1 : #833DBA;
$green1 : #5DA544;

$white : #FFF;

$successgreen : #5DA544;
$accidentred : #F24722;


$hamburger-layer-color: #FFF;
$hamburger-hover-opacity: 1;

$max-line-length : 30em;

$header-z : 100;
$modal-z : $header-z + 1;

@mixin modaloverlay {
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $modal-z;	
}

@mixin modal {
	background-color: #FFF;
	padding: 1em;
	min-width: 14em;	
}
