@keyframes slide-up {
    0% { top: 0; }
    80% { top:0; }
    100% { top:-10rem; }
}

.callout {
    position: fixed;
    top:0;left:0;right:0;
    padding: .635rem 1rem;
    border:0;
    overflow: hidden;
    border: none; //@TODO Foundation override
}

.slide-up {
    animation-name: slide-up;
    animation-duration: 5s;
    animation-fill-mode: forwards;    
}