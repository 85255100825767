@import '../scss/_variables.scss';

.ActivityButton {
	display: inline-block;
	text-align: center;
	button {
		font-size: 42px;
		padding:1.5rem;
		display: inline-block;
		border-radius: 50%;
		background-color: black;
	}

	.ActivityButton__label {
		font-weight: bold;
		font-size: 80%;
	}

	&.Pee {
		button {
			background-color: $yellow1;
			// box-shadow: .125em .125em .5em $yellow2;
		}
		.ActivityButton__label {color: $yellow2;}
	}

	&.Poo {
		button {
			background-color: $brown1;
			// background-image: radial-gradient(#FFF, $brown1 80%);
		}
		.ActivityButton__label {color:$brown2;}
	}

	&.Eat {
		button {background-color: $red1;}
		.ActivityButton__label {color: $red2;}
	}

	&.Drink {
		button {background-color: $blue1;}
		.ActivityButton__label {color: $blue2;}
	}

	&.Sleep {
		button { background-color: $purple1; }
		.ActivityButton__label {color: $purple1;}
	}

	&.Exercise {
		button { background-color: $green1; }
		.ActivityButton__label {color: $green1;}
	}
}