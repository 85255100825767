@import '../scss/_variables.scss';

// .EditModalOverlay {
// 	@include modaloverlay;
// }

// .EditModeModal {
// 	@include modal;
// }

.ActivityEditMode {
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	padding: 1em;
	z-index: $modal-z;
	background-color: #FFF;
	overflow-y: scroll;

	.rdt {

		.rdtPicker {
			position: static;
			font-size: 16px;
		}

	}

	label {
		font-weight: bold;
	}
}

.ActivityEditMode__close-btn {
	font-size: 2em;
	display: block;
	margin-left: auto;
	padding-left: .25em;
	padding-bottom: .25em;
	color: $brown1;
	cursor: pointer;
}

.ActivityEditMode__title {
	text-align:center;
}

.ActivityEditMode__buttons {
	margin-bottom: 2em;
}