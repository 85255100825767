@import '../scss/_variables.scss';

.notify-me {
    display: flex;
    align-items: center;
    // justify-content: center;

    .switch {
        margin-bottom: 0;
        margin-left: .5em
    }
    .switch-paddle {
        border-radius: 5000px;
        &::after {
            border-radius: 5000px;
        }
    }
    input:checked ~ .switch-paddle {
        background-color: $purple1;
    }
    input:disabled ~ .switch-paddle {
        opacity: .5; 
    }

}