// Place all the styles related to the Puppies controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.puppy-header {
    text-align:center;
    max-width: 20em;
    margin: 0 auto;

    .puppyshot {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width:50%;
    }
}

.puppyshot {
    border-radius : 50% 50% 50% 50%;
    max-width: 200px;
}

.puppies-list {
    list-style: none;
    padding:0;
    margin:0;    
}
.puppies-list__item {
    text-align:center;
    a { display:block; }
    // .puppyshot { display:block; }

    & + .puppies-list__item {
        margin-top: 2rem;
    }
}

.nexts {
    display: flex;

    .next {
        flex-grow: 1;
        line-height: 1.2;
        display: inline-block;
        text-align:center;
    }

    .next--pee {
        padding-right: .25em;
    }
    .next--poo {
        padding-left: .25em;
    }

    .no-prediction-msg {
    }
}

.history {
    .accident .history__time {
        color: #F00;
    }

    // @TODO
    .history__row {
        // position: relative;
        // display:inline-block;
        .ActivityEditButton { margin:0; margin-left: .5em; }
        form { display:inline-block; }
    }
}