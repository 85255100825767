.field_with_errors input,
.field_with_errors input:focus,
.error_explanation {
    border-color: $red1;
    color: $red1;
}

.field.checkbox-w-label {
	display: flex;
	margin-bottom: 1rem;

	label {
		flex-grow: 1;
		line-height: 1.2;
	}
}